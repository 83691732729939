import React, {useState, useRef, useEffect} from 'react'
import {navigate} from 'gatsby'
import styled from 'styled-components'
import slideshow_arrow from '../imgs/slideshow_arrow_right.png';
import arrow_white from '../imgs/arrow_white.png';

import {animated as a, useSpring, useSprings } from 'react-spring';
import {useStore} from '../state'
import {useColorModeContext} from '../layouts'
import css from '@styled-system/css'
import { useDrag } from 'react-use-gesture'
import clamp from 'lodash-es/clamp'
import {pwindow, isMobile} from '../utils'
import { maxWidth } from 'styled-system';


const ImageBlockWrapper = styled.div`


.main_image_container {
    height: 400px;

    position: relative;
    
    .image_count_reference {
        position: relative;
        
        .bg, .descr {
            position: absolute;
            bottom: 0px;
            height: 80px;
            width: 100%;
            text-align: right;
            

        }
        .bg {
            background: black;
            opacity: 0.7;
        }
        color: white;
        
        line-height: 80px;
        text-aligin: center;
        
        .count {
            font-size: 20px;
        }

        .text {
            padding: 0px 20px;
        }

    }
}

.main_image{
    width: 100%;

    &.animated {
        position: fixed;
    }

    img {
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
    
}

.descr {
    cursor: pointer;
}

.rest_images {

    display: flex;

    
    .rest_main_image {
        margin-left: 5px;
        margin-top: 5px;
    }

    .rest_main_image:first-child {
                margin-left: 0px;
                background-repeat: no-repeat;
                background-size: cover;
    }
    

    & > div {
        height: 100px;
    }
    
    .first.rest_main_image {
        width: 45%;
    }

    .last.rest_main_image {
        width: 55%;
    }

    .rest.rest_main_image {
        width: 20%;
    }

    .rest.rest_main_image:last-child {
        width: 35%;
    }
}


${css({
    width: ['100%', '580px']
})}
`


const imgSize = (img) => {   

    const {naturalHeight, naturalWidth} = img
    const mainImageAspect = naturalWidth/naturalHeight
    
    const maxWidth = isMobile() ?  (pwindow.innerWidth -20) : (naturalWidth < 1040) ? naturalWidth : 1040

    const _height = isMobile() ?  maxWidth / mainImageAspect : pwindow.innerHeight * 0.8 


    let width = _height * mainImageAspect
    let height = _height

    if(width > maxWidth) {
        width = maxWidth
        height = maxWidth / mainImageAspect
    }
    



    return {height, width}

}


const MainImage = ({src, images_length}) => {
    
    const ref = useRef()
    const imgRef = useRef()

    

    const [{mode}, dispatchColorMode] = useColorModeContext()

    
    

    const [hover, setHover] = useState(false)
    const [mainImageWidth, setMainImageWidth] = useState(0)
    const [mainImageAspect, setMainImageAspect] = useState(1)
    const [loaded, setLoaded] = useState(false)
    const {image_show, startImageShow} = useStore()
    
    const {out} = useSpring({
        from: {out: 0, opacity: 1},
        out: hover ? 25 : 50,
        opacity: hover ? 1 : 0.5
    })

    const [{width, height, left}, set] = useSpring(() => ({
        width: mainImageWidth, height: 10, left:0,
        onRest: (e) => {

            e.show === 1 && navigate('images')
            
        }
    }))

  
    useEffect(() => {
        if(ref.current){
            const {left, width, height} = ref.current.getBoundingClientRect()
            set({left, width, height})
            setMainImageWidth(width)
        }

        

    }, [imgRef.current, ref.current])
   
    if(image_show) {
        const {width, height} = imgSize(imgRef.current)
        set({width, height, left: (pwindow.innerWidth - width)/2,   show: 1})
        mode === 'dark' || dispatchColorMode('dark')
    }else {
        mode === 'default' || dispatchColorMode('default')
    }

    

    const setImageLoaded = (e) => {
        setLoaded(true)
        setImageAspect(e)
    }

    const setImageAspect = (e) => {
        const {naturalHeight, naturalWidth} = e.target
            
        naturalHeight && setMainImageAspect(naturalWidth/naturalHeight)
    }

   


    return  <div ref={ref} className="main_image_container">
        <a.div className={`main_image ${image_show ? 'animated': ''}`} style={{width, height, left}} >
            <img ref={imgRef} src={src} onLoad={setImageLoaded}  />
        </a.div>
        {image_show 
                ? null
                : <div className="image_count_reference">
                    <div className="bg"/>
                    <div className="descr" onClick={startImageShow}  onMouseOver={() => setHover(true)} onMouseOut={()=> setHover(false)}>
                        <span className="count">{images_length}</span>
                        <span className="text">Object Images</span>
                        <a.span className="arrow" style={{marginRight: out.interpolate(o => `${o}px`)}}><img src={arrow_white}/></a.span>
                    </div>
                    

            </div>}
    
    </div>
}

const Teasers = ({images}) => {
    
    const {image_show} = useStore()

    images.sort((a,b) => a.order - b.order)
    const images_length = images.length
    const [main_image, ...rest] = images
    const [first_rest_main_images, ...rest_main_images] = rest.slice(0,3)
    
    return <ImageBlockWrapper>
       
        <MainImage src={main_image.uris._1000} images_length={images_length} />            
        {image_show 
                ? null
                : <div className="rest_images">
            <div className="first rest_main_image" style={{background: `url(${first_rest_main_images.uris._200x200})  80% 80%`}}/>
            {rest_main_images.length === 1 
                ? <div className="last rest_main_image" style={{background: `url(${rest_main_images[0].uris._200x200})  50% 50%`}}/>
                : rest_main_images.map((img, i) => {
                    return <div className="rest rest_main_image" key={i} style={{background: `url(${img.uris._200x200})  50% 50%`}}/>
            })}
        </div>
        }

    </ImageBlockWrapper>
}

const ImageShowWrapper = styled.div`
width: 100%;


    .images {
        will-change: transform;
        display: flex;
        height: 80vh;
        overflow: hidden;

        > div {
            position: absolute;
            height: 80vh;
            justify-content: center;
            width: 100%;
            
        }

        > div:not(:first-child) {
            display: none;
        }
    }
    
  .show_image {
      position: absolute;
    height: 100%;
    will-change: transform;

    

    
  }
  
.navbar {
    position: absolute;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0px auto;

    .el {
        min-width: 60px;
        height: 30px;
        margin: 5px 20px;

        &.left_arrow {

            position: absolute;
            transform: rotate(180deg);
            margin-top: 37vh;
            left: -70px;
            
        }

        &.right_arrow {

            position: absolute;
            right: -70px;
            margin-top: 30vh;
         

        }

        img {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #666;
            }
        }

        
    }
    .blocks {
        margin-top: 10px;
        position: fixed;
        bottom: 10px;

        display: flex;
        justify-content: center;
        .squares {
            display: flex;
        }        

        &.mobile_hor {
            flex-direction: column;
            height: fit-content;
            align-items: flex-end;
            .squares {
                flex-direction: column;
            }
        }
    }

    .block {
        cursor: pointer;
        width: 8px;
        height: 8px;
        border: 1px solid white;
        margin: 10px;
        &.active {
            background: white;
        }
    }
}${
    css({
        '.show_image': {
            'img': {
                maxWidth: [pwindow.innerWidth - 20, 1040],
                maxHeight: pwindow.innerHeight * 0.8
            },

            marginLeft:[0, 0],

        },
        '.el.arrow': {
            display: ['none', 'block']
        },
        '.blocks': {
            width: [`calc(100% - 60px)`, '1040px']
        },
        '.navbar': {
            zIndex: [0, 2]
        }
    })
}
`



const ImageShowParent = ({images}) => {
    const findFormat = () => pwindow && pwindow.innerWidth/pwindow.innerHeight < 1 ? 'vert' : 'hor'
    const [format, setFormat] = useState(findFormat())

    const defineFormat = () => void(findFormat() !== format ? setFormat(findFormat()) : null)

    useEffect(() => {

        window.addEventListener('resize', defineFormat)

    }, [format])

    return <ImageShow key={format} {...{images, format}}/>
    
   
}

const ImageShow = ({images, format}) => {
    const [index, setIndex] = useState(0)
    const [width, setWidth] = useState(isMobile() ? pwindow.innerWidth - 20 : 1040)

    const [touchStartX, setTouchStartX] = useState(0)
    const [touchDistance, setTouchDistance] = useState(0)
    
    const ref = useRef()

    const [props, set] = useSprings(images.length, i => ({
        x: i * pwindow.innerWidth,
        scale: 1,
        display: 'flex'
      }))

    
      const update = () => set(i => {
        if (i < index - 1 || i > index + 1) return { display: 'none' }
        const x = (i - index) * pwindow.innerWidth
        const scale = 1
        return { x, scale, display: pwindow.innerWidth ? 'flex' : 'none' }
      })
      
  

    useEffect(() => void(update()), [index])
    


    const moveImage = () => {
        touchDistance < 0 ? setIndex(images.length - 1 > index ?  index + 1: index) : setIndex(index === 0 ? 0 : index - 1)
        
    }    

    return (<ImageShowWrapper ref={ref}>

        <div className="navbar">
            <div className="el arrow left_arrow">{index === 0 ? null : <img onClick={() => setIndex(index - 1)} src={slideshow_arrow}/>}</div>
            <div className={`el blocks ${format === 'hor' && isMobile() ? 'mobile_hor' : ''}`}>
                <div className="squares">
                    {images.map((_, i) => <div key={i} onClick={() => setIndex(i)} className={`block ${index === i ? 'active': ''}`}/>)}
                </div>
            </div>
            <div className="el arrow right_arrow">{index === images.length - 1 ? null :<img onClick={() => setIndex(index + 1)}  src={slideshow_arrow}/>}</div>
        </div>
       
        <div className="images" >
           
            {props.map(({ x, display }, i) => {
                return (
                    <a.div onTouchStart={(e) => setTouchStartX(e.nativeEvent.touches[0].clientX)} onTouchMove={(e) => setTouchDistance(e.nativeEvent.touches[0].clientX - touchStartX)} onTouchEnd={(e) => moveImage()}  key={i} style={{ display, width, left: pwindow.innerWidth ? x.interpolate(x => `${x}px`) : i*3000  }}>
                        <div className="show_image">
                            <img src={`${images[i].uris._1000}`} />
                        </div>
                    </a.div>
                    )
            })}</div>
        
      
      </ImageShowWrapper>)
}

export const ImageBlock = ({images, full}) => {

    images.sort((a,b) => a.order - b.order)
    
    return full 
    ? <ImageShowParent images={images} />
    : <Teasers images={images} />
    

}