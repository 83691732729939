import React, {useState} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import styled from 'styled-components'
import css from '@styled-system/css'
import arrow from '../imgs/arrow.png';
import arrow_white from '../imgs/arrow_white.png';
import {animated as a, useSpring } from 'react-spring';
import {ImageBlock} from '../components/ImageShow'
import Project from '../components/Project'
import {useStore} from '../state';
import {Hr} from '../components/Layout'
import Seo from '../components/Seo'

const DesciptionBlockWrapper = styled.div`
    text-align: center;
    text-align: -webkit-center;
    .project_text {
        font-family: 'Roboto Slab';
        max-width: 400px;
        margin-right: 10px;
        p {
            padding-bottom: 10px;
            line-height: 1.5rem;
            font-size: 1rem;
        }
    }
    ${css({
        padding: ['20px 0px', 0],
        paddingLeft: [0, '5px'],

        '.project_text': {
          textAlign: ['left', 'justify'],
          padding: ['10px', '0px']
        }
    
        
    })}
`

const DesciptionBlock = ({project_text}) => {
    return <DesciptionBlockWrapper><div className="project_text" dangerouslySetInnerHTML={{__html: project_text}}/></DesciptionBlockWrapper>
}


const ParameterBlockWrapper = styled.div`

padding: 20px 0px;
.paramater_row {
    display: flex;
    padding: 10px 5px;
    line-height: 1.5rem;
    border-bottom: 1px dotted #ccc;
    .label {
        font-family: 'Ubuntu';
        font-size: 18px;
        font-weight: 500;
        min-width: 150px;
    }

    .value {
        font-family: 'Ubuntu';
        font-size: 18px;
        font-weight: 300;
    }
}

${css({
    width: ['100%', '580px'],
   '.paramater_row:last-child': {
      borderBottom: ['1px dotted #ccc', 'none']
    
    }
})}
`


const powRegExp = new RegExp(/\^(\d)/)

const ParameterBlock = ({parameters, parameters_uuid}) => {

    if(!parameters_uuid) return null

    const existing_parameter_uuids = parameters.map(p => p.parameter_uuid)

    const project_parameters = parameters_uuid
        .filter(p => existing_parameter_uuids.includes(p.parameter_uuid))
        .map(p => ({...p, ...parameters.find(pp => pp.parameter_uuid === p.parameter_uuid)}))

    project_parameters.sort((a, b) => a.order - b.order)    
    return <ParameterBlockWrapper>{project_parameters.map((p, i) => {

        const pow = p.value.match(powRegExp)

        const value = pow 
        ? `${p.value.slice(0,pow.index)}<sup>${pow[1]}</sup>${p.value.slice(pow.index + pow[0].length)}`
        : p.value

        return <div key={i} className="paramater_row">
            <div className="label">{p.label}: </div>
            <div className="value" dangerouslySetInnerHTML={{__html: value}}/>
        </div>
    })}</ParameterBlockWrapper>
}




const OtherProjectsWrapper = styled.div`
margin-top: 0px;
padding-bottom: 40px;
h3{
  
}
h2 {
      
  margin: 10px 0px  10px;
  color: #999;
}
> div {
  margin-top: 10px;

    display: flex;
    flex-wrap: wrap;

   
    justify-content: space-between;

    .other_projects_block {
        

        .categories {
            font-size: 1rem;
            padding: 6px;
            border: 1px solid #666;
            display: inline-block;
            margin: 0px 4px;

        }
    }

    .teasers {
        display: flex;
        flex-wrap: wrap;
        
        
    }

    .other_project_teaser {
        display: block;
        overflow: hidden;
        margin-top: 20px;


      &:hover {
        box-shadow: 0px 1px 10px #ccc;

      }


        position: relative;    
        .title {
            position: absolute;

            display: block;
            z-index: 2;
            left: 10px;
            bottom: 10px;
            background: white;
            padding: 10px;
            padding-left: 5px;
            text-decoration: none;
            color: black;
            font-weight: 500;

            .arrow {
                padding-right:75px;
                &:hover {

                }
            }
        }
        
        .image {
            width: 240px;
            height: 240px;
        }  
    } 
${css({
  flexDirection: ['column', 'row'],
  alignItems: 'center'
})}
}

  
`


const OtherProjectsBlock = ({categories, projects}) => {

    const title =  categories.length > 0 
    ? <span className="header">other <span className="categories">{categories.map(c => c.label).join(', ')}</span> Projects</span>
    : <span className="header">All other Projects</span>

    return <div className="other_projects_block">
        <div className="teasers">
            {projects.map(p => <OtherProjectTeaser key={p.project_uuid} project={p} />)}
        </div>
        
    </div>
}

const OtherProjectTeaser = ({project}) => {

    const [hover, setHover] = useState(false)
    const {out, opacity} = useSpring({
        from: {out: 0, opacity: 1},
        out: hover ? 10 : 5,
        opacity: hover ? 1 : 0.5,
    })
    const uri = project.images && project.images[0] ? (project.images.find(i => i.order === 1) ? project.images.find(i => i.order === 1).uris['_200x200'] : project.images[0].uris['_200x200']): ""
    return  <Link className="other_project_teaser" to={`/projects/${project.project_slug}`} onMouseOver={() => setHover(true)} onMouseOut={()=> setHover(false)}>
        <a.div className="image" style={{background: `url(${uri})`, opacity: opacity.interpolate(o => `${o}`)}}/>
        <div className="title">
            <a.div className="arrow"  style={{background: `url(${arrow}) 100% 50% no-repeat`, paddingLeft: out.interpolate(l => `${l}px`)}}>{project.project_name}</a.div>
        </div>
    </Link>

}

const OtherProjects = ({project_categories, navigation_categories, other_category_projects, other_noncategory_projects}) => {

    const navigation_categories_uuid = navigation_categories.map(n => n.category_uuid)
    const first_block = other_category_projects
        .slice(0,2)

    const nav_block = other_category_projects
        .slice(2)
        .find(p => p.categories_uuid.filter(c => navigation_categories_uuid.includes(c.category_uuid)))
    
    const primary_block_length = nav_block ? first_block.length + 1 : first_block.length
    const rest_block = other_noncategory_projects.slice(0, 3 - primary_block_length) 

    
    return <OtherProjectsWrapper>
            <h3>More Projects</h3>
            <div>
            {first_block.length > 0 ? first_block.map(p => <OtherProjectTeaser key={p.project_uuid} project={p} />): null}
            {nav_block ? [nav_block].map(p => <OtherProjectTeaser key={p.project_uuid} project={p} />) : null}
            {rest_block.length > 0 ? rest_block.map(p => <OtherProjectTeaser key={p.project_uuid} project={p} />) : null}
            </div>
        </OtherProjectsWrapper>
}

export default (props) => {
    
    const {image_show} = useStore()

    const {data, pageContext} = props
    const {project, other_category_projects, categories, other_noncategory_projects} = data
    const {images, project_text, parameters_uuid, categories_uuid} = project.nodes[0]
    const parameters = data.allMongodbCmsParameters.nodes
    const project_categories = categories.nodes.filter(c => categories_uuid.includes(c.category_uuid) && c.group === "project")
    const navigation_categories = categories.nodes.filter(c => categories_uuid.includes(c.category_uuid) && c.group === "navigation")

    
    const [{opacity}, set] = useSpring(() => ({
        from: {opacity: 1}
    }))

    if(image_show){
        set({opacity:0})
    }
    images.sort((a,b) => a.order - b.order)

    return <>
    
       <Project project_name={pageContext.project_name} categories={project_categories}>
            <div>
                <ImageBlock images={images}/>
                <a.div style={{opacity}}><ParameterBlock {...{parameters, parameters_uuid}} /></a.div>
            </div>
            <a.div style={{opacity}}><DesciptionBlock project_text={project_text}/></a.div>
       </Project>
      
       {image_show ? null : <> <Hr/> <OtherProjects {...{
           project_categories, 
           navigation_categories, 
           other_category_projects: other_category_projects.nodes,
           other_noncategory_projects: other_noncategory_projects.nodes}} /></>
       }
    </>
}



export const pageQuery = graphql`
    query($project_uuid: String, $categories_uuid: [String]){
        project: allMongodbCmsProjects(filter: {project_uuid: {eq: $project_uuid}}) {
          nodes {
            project_slug
            project_name
            project_uuid
            categories_uuid
            project_text
            parameters_uuid {
                parameter_uuid
                value
              }
            images {
              uris {
                _200x200
                _600
                _1000
              }
              order
            }
          }
        }
        other_category_projects: allMongodbCmsProjects(filter: {project_uuid: {ne: $project_uuid}, categories_uuid: {in: $categories_uuid}}) {
             
            nodes {
              project_slug
              project_name
              project_uuid
              categories_uuid
              project_text
              parameters_uuid {
                  parameter_uuid
                  value
                }
              images {
                uris {
                  _200x200
                }
                order
              }
            }
          }
          other_noncategory_projects: allMongodbCmsProjects(filter: {project_uuid: {ne: $project_uuid}, categories_uuid: {nin: $categories_uuid}}) {
             
            nodes {
              project_slug
              project_name
              project_uuid
              categories_uuid
              project_text
              parameters_uuid {
                  parameter_uuid
                  value
                }
              images {
                uris {
                  _200x200
                }
                order
              }
            }
          }
       
        allMongodbCmsParameters {
          nodes {
            label
            parameter_uuid
            order
          }
        }
        categories: allMongodbCmsCategories {
          nodes {
            label
            color
            category_uuid
            order
            group
          }
        }
      }
      

`