import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import {fontSize, layout, space} from 'styled-system'


const ImgTextWrapper = styled.div`

display: flex;
text-align: justify;
justify-content: space-between;

img {
    object-fit: cover;
}
.text {
    font-family: 'Roboto Slab';
    
    margin-right: 10px;
}

${css({
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'normal'],
    textAlign: ['left', 'justify'],


    'img': {
        width: ['calc(100vw - 20px)', 'auto'],
        maxWidth: ['400px', '580px']
    },
    '.text': {
        marginTop: ['2rem', 0],
        maxWidth: ['400px', '500px'],
        lineHeight: ['1.35rem', '1.6rem'],
        fontSize: ['1rem'],
        padding: ['0px 10px', '0px'],
    }
})}
`

export const ImgText = ({img_path, children}) => {



    return <ImgTextWrapper>
        <div className="img"><img src={`${img_path}`}/></div> 
        <div className="text">{children}</div>
    </ImgTextWrapper>
}



const RowWraper = styled.div`
display: flex;
justify-content: space-between;
padding: 10px;

${css({
    flexDirection: ['column', 'row'],
})}
${layout}
${space}

`

export const Row = ({children, ...props}) => <RowWraper {...props}>{children}</RowWraper>



const ColumnWrapper = styled.div`

display: flex;
flex-direction: column;

h3 {
    margin: 20px 0px;
}

a {
    text-decoration: underline;
}
p {
    margin-bottom: 1rem;
}

${layout}
${space}

`

export const Column = ({children, ...props}) => {

    return     <ColumnWrapper {...props}>{children}</ColumnWrapper>

}



export const Hr = styled.div`

width: 100%;
height: 1px;
background: #fff;
margin: 34px 0px;

`


export const H1 = styled.h1`

${space}
`