import React, {useState} from 'react'
import styled, {keyframes} from 'styled-components'
import css from '@styled-system/css'
import arrow from '../imgs/arrow.png'
import arrow_white from '../imgs/arrow_white.png'
import {useColorModeContext} from '../layouts'
import {navigate} from 'gatsby';
import { useStore } from '../state'
import Seo from './Seo'


const ProjectWrapper = styled.div`

position: relative;

.project_body {


 display: flex;

 flex-wrap: wrap;




 ${css({
     flexDirection: ['column', 'row'],
     justifyContent: ['space-between'],
     marginTop: ['10px', '10px'],            

 })}
}

${css({
   
   '&:not(.images) .category': {
       marginTop: ['20px', 0]
   }
       
   

})}
`

const toleft = keyframes`

    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(-30px, 0px, 0);
    }

`
const width = keyframes`

    from {
        width: 0px;
    }

    to {
        width: 20px;
    }

`

const CategoryWrapper = styled.div`

position: relative;
overflow: hidden;
cursor: pointer;
span {
    display: flex; 
    width: fit-content;
    transform: translate3d(0, 0, 0);
    line-height: 1.5rem;
    
    &:hover {
    
        animation: ${toleft} 0.5s ease;
        transform: translate3d(-30px, 0px, 0);
    
    }
    
    &:before {
        content: '';
        display: block;
        background: ${({mode}) => mode === 'dark' ? `url(${arrow_white})` : `url(${arrow})`};
        background-repeat: no-repeat;
        background-position: 100% 45%;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        margin-right: 10px;
    }
    &:hover {
    
        &:after {
            content: '';
            display: block;
            background: ${({mode}) => mode === 'dark' ? `url(${arrow_white})` : `url(${arrow})`};
            background-repeat: no-repeat;
            background-position: 100% 45%;
            width: 20px;
            height: 20px;
            animation: ${width} 0.5s ease;
            margin-left: 10px;
            transform: rotate(180deg);
        }
    
    }
    
}



`

const Category = (c) => {

    const [{mode}] =  useColorModeContext()
    const {stopImageShow} = useStore()
    


    return <CategoryWrapper className="category" onClick={() => { stopImageShow(); navigate(`/projects/?category_uuid=${c.category_uuid}`) }} {...{mode}}>
        <span>{c.label}</span>
    </CategoryWrapper>
}

const ProjectBreadCrumb = ({categories}) => {


    return <div>{categories.map((c, i) => <Category key={i} {...c}/>)}</div>
}

const Project = ({project_name, categories,children, className}) => {


    return (<ProjectWrapper className={className} >

        <Seo title={`${project_name} - NAICE`} description={`${project_name} - ${categories.map(c => c.label).join(', ')} architecture project`}/>
      


         <ProjectBreadCrumb {...{categories}}/>
         <h1>{project_name}</h1>

            <div className="project_body">
                {children}
            </div>
    </ProjectWrapper>)
}


export default Project